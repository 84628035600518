<div class="modulo modulo-rede-social">
  <div [ngSwitch]="template">
    <div *ngSwitchCase="'home'" class="template-home">
      <div class="content"> 
        <div class="container"> 
          <span class="hashtag wow fadeIn" data-wow-delay="0.1s">{{data.params.hashtag}}</span>
          <div class="box">
            <div class="center">
              <div class="list-items">
                <div class="item wow slideInLeft" data-wow-delay="0.5s">
                  <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
                    <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/facebook-2.svg" width="55px" height="55px" alt="Facebook" />
                  </a> 
                </div> 
                <div class="item wow slideInRight" data-wow-delay="0.8s">  
                  <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
                    <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/instagram-2.svg" width="55px" height="55px" alt="Instagram" />
                  </a>  
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="center">
              <article class="text-box wow fadeIn" data-wow-delay="0.5s" [innerHtml]="data.params.texto | safeHtml"></article>
            </div>
          </div> 
          <div class="box">
            <div class="center"> 
              <a class="wow fadeIn" data-wow-delay="1s" href="{{data.params.trip_advisor}}" target="__blank" title="Trip Adivisor">
                <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/trip-advisor.svg" width="100px" height="80px" alt="Trip Adivisor - Costa Azul" />
              </a> 
            </div>  
          </div>  
        </div>
      </div>   
      <img width="100%" height="60px" class="onda" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/onda/onda-2.svg" alt="Onda" />
    </div>
    <div *ngSwitchCase="null" class="default">
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
              <i class="fab fa-facebook"></i>
            </a> 
          </li> 
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
              <i class="fab fa-instagram"></i>
            </a>  
          </li>
          <li>
            <a href="{{data.params.blog}}" target="__blank" title="Blog">
              <i class="fab fa-blogger"></i>
            </a>  
          </li> 
        </ul>  
      </div>  
    </div>
    <div *ngSwitchCase="'footer'" class="default-footer">
      <h3 class="modulo-title" *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li *ngIf="data.params.ativar_instagram">
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/instagram.svg" width="40px" height="40px" alt="Instagram" />
            </a>  
          </li>
          <li *ngIf="data.params.ativar_facebook">
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/facebook.svg" width="40px" height="40px" alt="Facebook" />
            </a> 
          </li> 
          <li *ngIf="data.params.ativar_youtube">
            <a href="{{data.params.youtube}}" target="__blank" title="Youtube">
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/youtube.svg" width="40px" height="40px" alt="Youtube" />
            </a>  
          </li> 
        </ul>  
      </div>  
    </div>
    <div *ngSwitchCase="'page-contato'" class="default-contato">
      <h3 class="modulo-title">Redes Sociais</h3>
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
              <i class="fab fa-facebook"></i>
            </a> 
          </li> 
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
              <i class="fab fa-instagram"></i>
            </a>  
          </li>
          <li>
            <a href="{{data.params.blog}}" target="__blank" title="Blog">
              <i class="fab fa-blogger"></i>
            </a>  
          </li> 
        </ul>  
      </div>  
    </div>
    <div *ngSwitchCase="'blog'" class="blog">
      <div class="blog-redes-sociais">
        <div class="module-title">
            <h3>{{data.titulo}}</h3>
        </div> 
        <div class="module-content">
            <ul>
                <li *ngIf="data.params.ativar_instagram">
                    <div class="item">
                      <a href="{{data.params.instagram}}" target="__blank" title="Instgram">
                        <img src="/images/icons/instagram.svg" width="50px" height="50px" />
                      </a>
                    </div>
                </li>
                <li *ngIf="data.params.ativar_facebook">
                    <div class="item">
                        <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
                          <img src="/images/icons/facebook.svg" width="50px" height="50px" />
                        </a>
                    </div>    
                </li>
                <li *ngIf="data.params.ativar_youtube">   
                    <div class="item">
                      <a href="{{data.params.youtube}}" target="__blank" title="Youtube">
                        <img src="/images/icons/youtube.svg" width="50px" height="50px" />
                      </a>
                    </div>    
                </li>
            </ul>       
        </div>
    </div>
    </div>  
  </div>  
</div> 
